import React from "react";
import Page from "../layouts/Page";
import _ from "lodash";
// import Contact from '../components/Contact';

const HERO_IMAGE_WIDTH_PX = 400;

export default class Connecteur extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: (typeof window !== "undefined" && window.LAYOUT) || "grid"
    };
  }

  render() {
    return (
      <Page title={this.props.title} description={this.props.description}>
        <section className="hero first-hero is-primary">
          <div className="hero-body">
            <div className="container" style={{maxWidth: "1280px"}}>
              <h1 className="title">Connecteurs aux téléservices nationaux</h1>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container" style={{maxWidth: "1280px"}}>
            <div class="tabs is-large">
              <ul>
                <li class={ this.props.activePage=='ins' ? "is-active":"" }>
                  <a href="/connecteurs/ins">INS</a>
                </li>
                <li class={ this.props.activePage=='dmp' ? "is-active":"" }>
                  <a href="/connecteurs/dmp">DMP</a>
                </li>
                <li class={ this.props.activePage=='mssante' ? "is-active":"" }>
                  <a href="/connecteurs/mssante">MS Santé</a>
                </li>
                <li class={ this.props.activePage=='prosanteconnect' ? "is-active":"" }>
                  <a href="/connecteurs/prosanteconnect">Pro Santé Connect</a>
                </li>
              </ul>
            </div>
            { this.props.content }
          </div>
        </section>
        { this.props.articles }
      </Page>
    );
  }
}

import React from "react";
import Connecteur from '../../components/Connecteur';
import MssanteLogo from '../../media/connecteurs/mssante-logo.png';

export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "mssante",
      title: "Messagerie MS Santé",
      description: "Un connecteur simple qui s'appuie sur la messagerie des professionnels de santé.",
      content:
      <div>
        <div class="columns section-panel">
            <div class="column alt-column is-8">
                <div class="content is-medium">
                    <h2>Le service MS Santé</h2>
                    <p>La messagerie sécurisée de santé, est un espace de confiance au sein duquel les professionnels de santé (médecins, infirmiers, etc.), en ville comme à l'hôpital peuvent s'échanger par email des données de santé.</p>
                    <p>La MSSanté réunit un ensemble d'opérateurs de messagerie qui respectent les exigences de sécurité et d'intéropérabilité définies par l'ANS.</p>
                    <p>Ce connecteur est en cours de développement mais n'hésitez pas à nous contacter si vous souhaitez faire parti des bêta testeurs !</p>
                </div>
            </div>
            <div class="column alt-column is-2 is-offset-1">
                <figure class="image">
                  <img src={ MssanteLogo } alt="Logo MS Santé" />
                </figure>
                <a class="button is-danger" href="../../contact">On discute ?</a>
            </div>
        </div>
      </div>

    };
  }

  render() {
    return (
        <Connecteur 
            content={this.state.content} 
            activePage={this.state.page} 
            title={this.state.title}
            description={this.state.description}
        />
    );
  }
}
